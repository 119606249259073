<template>
  <div class="bg blue">
  </div>
  <div class="container-in w-800">
    <div class="sheet smart-wrap">
      <div class="col-12" >
        <svg class="center" width="80" height="80" viewBox="0 0 220 220" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M70 115L100 145L150 85" stroke="#56E200" stroke-width="12" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M110 210C165.228 210 210 165.228 210 110C210 54.7715 165.228 10 110 10C54.7715 10 10 54.7715 10 110C10 165.228 54.7715 210 110 210Z" stroke="#56E200" stroke-width="10"/>
        </svg>
      </div>
      <div class="col-12">
        <br>
        <h4 class="smart-text t-center" >Thank you for your purchase</h4>
        <p class="smart-text t-center">Payment Successful</p>
        <br><br>
        <p class="smart-text t-center">Your purchase has been completed successfully, we have sent you an email with the details.</p>
        <p class="smart-text t-center t-12">For more information contact us</p>
        <p class="smart-text t-center t-12">{{publicOrgPay.supportEmail + ' / ' + formatPhone(publicOrgPay.phone)}}</p>
        <br><br>
        <ui-buttons :color="'gray'" :position="'center'" :text="'Go to Invoice'" style="width:200px;" v-on:click="goToInvoice()" >
        </ui-buttons>
        <br>
      </div>
    </div>
  </div>
</template>
<script>
  import Vuex from 'vuex'
  import UiButtons from '../../components/ui/UiButton.vue'
  export default {
    name:'success',
    components:{
      UiButtons
    },
    data() {
      return {
        
      }
    },
    created() {
      console.log(this.$router);
    },
    methods: {
      ...Vuex.mapMutations(['setStatusReload']),
      goToInvoice(){
        //location.reload();
        this.setStatusReload(true);
        this.$router.push({
          path: '/checkout',
          query:{data: this.$router.currentRoute.value.query.data }
        });
      },
      goToSing(){
        //location.reload();
        this.setStatusReload(true);
        this.$router.push({
          path: '/smdlogin',
          query:{data: this.contactSK, mode:0 }
        });
      },
      formatPhone(data){
        console.clear()
        let value = data
        try {
          console.log(value);
          console.log(typeof value);
          value =  value.slice(0,2)+ '('+value.slice(2,5)+')' + value.slice(5,8)+'-'+value.slice(8)
        } catch (error) {
          console.log(error);
        }
        return value
      },
    },
    computed:{
      ...Vuex.mapState(['contactSK','publicOrgPay'])
    }
  }
</script>